<template>
    <div>
        <component
            :is="getFormieComponent('formField')"
            :field="field"
        >
            <component
                :is="getFormieComponent('input')"
                v-model="value"
                v-bind="getField(field).getInputBindings()"
                type="number"
            />
        </component>
    </div>
</template>

<script setup>
defineProps({
    field: {
        type: Object,
        required: true,
    }
});

const value = defineModel({
    type: [Number, String],
    required: true,
    set: newValue => parseInt(newValue, 10),
});
</script>
